import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../../utilities/axios.utils";
import { Button } from "@material-ui/core";
import AWS from "aws-sdk";
import { Col, Divider, Row } from "antd";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
//import "./IPcameraHistory.css";
import { getS3Client } from '../../../utilities/bucket.utils'; //20240205

let s3Obj;
function IPCameraHistory() {
//  let s3; // Declare s3 at the top level of the file
//  let s3Obj;
  const { device_id } = useParams();
  const [data, setData] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedSnapshotUrl, setSelectedSnapshotUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableDates, setAvailableDates] = useState([]); // Store dates with available images
  const currentDate = selectedDate.toISOString().slice(0, 10).replace(/-/g, "");
  const formattedCurrentDate = `${currentDate.slice(0, 4)}年${currentDate.slice(
    4,
    6
  )}月${currentDate.slice(6)}日`; //年月日
  const [snapshotNames, setSnapshotNames] = useState([]);
  const [more, setMore] = useState(5);
  const maxDate = new Date();

  const [selectedTime, setSelectedTime] = useState(new Date());
  const currentHour = selectedTime.getHours();

  const errorMessage = (
    <p style={{ whiteSpace: "pre-line" }}>
      {formattedCurrentDate + "画像が見つかりません"}
    </p>
  );

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div className="custom-datepicker-input" onClick={onClick}>
      <input type="text" value={value} readOnly />
    </div>
  );

  const initS3 = () => new Promise(async (resolve) => {
    if (s3Obj) {
      resolve(s3Obj);
    } else {
      const credentials = await getS3Client();
      s3Obj = new AWS.S3({
        credentials: credentials,
        region: "ap-northeast-1",
      });
      resolve(s3Obj);
    }
  });

  useEffect(() => {
    setMore(5);
  }, [selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(`/api/stations/${device_id}`);
        console.log(result);
        setData(result.result);
      } catch (error) {
        console.error("Error while fetching the data from api");
      }
    };

    fetchData();
  }, [device_id]);

  useEffect(() => {
    const getListOfAllImages = async () => {
      try {
        const s3 = await initS3();
        const response = await s3
          .listObjectsV2({
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Prefix: `${device_id}/`,
          })
          .promise();
        console.log("Test for list of Images", response);

        const snapshotDates = response.Contents.map((snapshot) => {
          const keyParts = snapshot.Key.split("/");
          const datePart = keyParts[3];
          const dateStr = datePart.split("_")[2];
          return new Date(dateStr);
        });
        console.log("snapshotDates", snapshotDates);
        setAvailableDates(snapshotDates); // Store dates with available images
      } catch (error) {
        console.log("No Images:", error);
      }
    };
    const getListofImage = async () => {
      try {
        const s3 = await initS3();
        const response = await s3
          .listObjectsV2({
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Prefix: `${device_id}/${currentDate}/${currentHour}/snapshot_`,
          })
          .promise();
        console.log("testjdhjaksdhasjk", response);

        if (response.Contents.length > 0) {
          const latestImage = response.Contents.reduce((prev, current) => {
            const prevTimestamp = parseInt(
              prev.Key.split("_")[2].replace(/-/g, "").replace(/:/g, "")
            );
            const currentTimestamp = parseInt(
              current.Key.split("_")[2].replace(/-/g, "").replace(/:/g, "")
            );
            return prevTimestamp > currentTimestamp ? prev : current;
          });

          setImageUrl(
            s3.getSignedUrl("getObject", {
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: latestImage.Key,
            })
          );

//          console.log("Signed URL:", imageUrl); // Logging the signed URL

//          setImageUrl(imageUrl);

          const snapshotNames = response.Contents.map(
            (snapshot) => snapshot.Key.split("/")[3]
          );

          console.log("snapshotNames", snapshotNames);
          setSnapshotNames(snapshotNames.reverse());
        } else {
          setSnapshotNames("no image");
        }
      } catch (error) {
        console.log("Error fetching latest image:", error);
      }
    };
    // "YYYY-MM-DD.";
    getListOfAllImages();
    getListofImage();
  }, [device_id, currentDate, currentHour]); //currenDate refresh to pic the latest image from that day

  // handle snapshot click event
  const handleSnapshotClick = async (snapshotName) => {
    const s3 = await initS3();
    const imageUrl = s3.getSignedUrl("getObject", {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: `${device_id}/${currentDate}/${currentHour}/${snapshotName}`,
    });
    setImageUrl(imageUrl);
    setSelectedSnapshotUrl(snapshotName);
  };
  const handleLatestClick = async () => {
    window.location.reload();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(date);
  };

  const handleMoreClick = () => {
    setMore(more + 5);
  };

  const handleMinimizeClick = () => {
    setMore(5);
  };

  // render snapshot list
  if (!data || Object.values(data).some((value) => value === undefined)) {
    return <p> api is not working</p>;
  }

  return (
    <div id="cameras" className="admin-page">
      <div className="admin-page-header">
        <div className="header-title">設置場所: {data.deployed_location}</div>
        <div className="header-description">河川制度: {data.river_system}</div>
      </div>
      <div className="admin-page-body">
        <div className="ipcameradetailpage">
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <div>
              {snapshotNames === "no image" ? (
                <div
                  style={{
                    width: "335px",
                    height: "435px",
                    backgroundColor: "#f1f1f1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {errorMessage}
                </div>
              ) : (
                <img src={imageUrl} alt="Loading . . ." />
              )}
              <div className="scrolllbar">
                <p>カメラ {device_id} 画像一覧:</p>
                {snapshotNames === "no image" ? (
                  <div
                    style={{
                      width: "300px",
                      height: "100px",
                      backgroundColor: "#f1f1f1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>{formattedCurrentDate + "画像が見つかりません"}</p>
                  </div>
                ) : (
                  <div className="snapshot_list">
                    {snapshotNames.slice(0, more).map((snapshotName) => {
                      const time = snapshotName.slice(-12, -7); // extract time from snapshot name
                      const hours = time.substring(0, 2);
                      const minutes = time.substring(3);
                      const formattedTime = hours + "時 " + minutes + "分";
                      return (
                        <div key={snapshotName} className="snapshot">
                          <img
                            src={s3Obj.getSignedUrl("getObject", {
                              Bucket: process.env.REACT_APP_S3_BUCKET,
                              Key: `${device_id}/${currentDate}/${currentHour}/${snapshotName}`,
                              Expires: 60 * 5, // URL expires in 5 minutes
                            })}
                            alt={snapshotName}
                            onClick={() => handleSnapshotClick(snapshotName)}
                            className={
                              selectedSnapshotUrl === snapshotName
                                ? "selected"
                                : ""
                            }
                            style={{
                              outline:
                                selectedSnapshotUrl === snapshotName ||
                                (selectedSnapshotUrl === "" &&
                                  snapshotName === snapshotNames[0])
                                  ? "2px solid blue"
                                  : "none",
                              cursor: "pointer",
                            }}
                          />
                          <p>{formattedTime}</p>
                        </div>
                      );
                    })}
                    {snapshotNames.length > 5 && // Only render if snapshotNames.length is greater than 5
                      (more < snapshotNames.length ? (
                        <Button onClick={handleMoreClick}>see more +5</Button>
                      ) : (
                        <Button onClick={handleMinimizeClick}>minimize</Button>
                      ))}
                  </div>
                )}
              </div>
            </div>
            <Col
              className="gutter-row"
              span={8}
              style={{ position: "fixed", left: "600px" }}
            >
              <h2 className="detailpagetitle">Station Detail</h2>
              <Divider></Divider>
              <h3>Device Id: {data.device_id}</h3>
              <h3>Sensor Model: {data.sensor_model}</h3>
              <h3>Lat/Long: {data.lat_long}</h3>
              <div className="button_area">
                <Button onClick={handleLatestClick} variant="outlined">
                  最新画像
                </Button>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  locale="ja"
                  dateFormat="yyyy年 MM月 dd日 HH:mm"
                  showTimeSelect
                  maxDate={maxDate}
                  timeCaption="時間"
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  minTime={new Date(0, 0, 0, 0, 0)} // Set minimum time to 12am (0 hours)
                  maxTime={new Date(0, 0, 0, 23, 59)} // Set maximum time to 11:59pm (23 hours and 59 minutes)
                  customInput={<CustomDatePickerInput value={selectedDate} />}
                 //tileContent={tileContent}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default IPCameraHistory;
