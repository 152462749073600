//import { Buffer } from "buffer";
//import S3 from "react-aws-s3";

//window.Buffer = window.Buffer || Buffer;

//const config = {
//  bucketName: process.env.REACT_APP_Web_S3_BUCKET,
//  region: "ap-northeast-1",
//  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
//  secretAccessKey: process.env.REACT_APP_S3_SECRET,
//};

//const ReactS3Client = new S3(config);

//export { ReactS3Client };

//20240119
import { Buffer } from "buffer";
import S3 from "react-aws-s3";
import AWS from "aws-sdk";
import axios from "../utilities/axios.utils";

window.Buffer = window.Buffer || Buffer;

const getS3Client = async () => {
  const { accessKeyId, secretAccessKey, sessionToken, expiration } =
    await axios.get("api/get-ec2-credential");
  if (accessKeyId && secretAccessKey) {
    // Log the obtained credentials
    //      console.log('Access Key ID:', accessKeyId);
    //      console.log('Secret Access Key:', secretAccessKey);
    //      console.log('Session Token:', sessionToken);
    //      console.log('Expiration:', expiration);
    const expireTime = new Date(expiration);

    // Log the expiration time
    console.log("Credentials expire at:", expireTime.toISOString());

    // Check if credentials are expired
    if (expireTime < new Date()) {
      console.log("Credentials are expired!");
      // Perform credential refresh logic here
    } else {
      console.log("Credentials are still valid.");
    }

    //    return new S3({
    //      accessKeyId,
    //      secretAccessKey,
    //      bucketName: process.env.REACT_APP_Web_S3_BUCKET,
    //      region: "ap-northeast-1",
    //    });
    return new AWS.Credentials({
      accessKeyId,
      secretAccessKey,
      sessionToken,
    });
  }

  return null;
};

export { getS3Client };
