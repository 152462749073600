
import React from "react";
import { useAtom } from "jotai";
import {
  Card,
  CardContent,
  Typography,
  Grid,
} from "@material-ui/core";
import { stationData, stationList, selectedStationAtom } from "../store";
import { getStatusCard, CustomCardHeader } from "./commonComponents";

function Default() {
  const [Data] = useAtom(stationData);
  const [stations] = useAtom(stationList);
  const [selectedStation] = useAtom(selectedStationAtom);
  const selectedStationData = stations.find(
    (s) => s.device_id === selectedStation
  );
  const min_setting = selectedStationData?.scale_setting_min;
  const max_setting = selectedStationData?.scale_setting_max;
  const close = selectedStationData?.standard_value;
  const open = selectedStationData?.standard_value_UL;

  const calculatedValue = !Data
    ? ""
    : Data.length !== 0
    ? ((Data.h1_angle / 1000) * (max_setting - min_setting)) / 100 +
      min_setting / 100
    : "";

  // Set variable X based on calculatedValue
  const gate_condition =
    calculatedValue > open * 0.01
      ? "1"
      : calculatedValue < close * 0.01
      ? "0"
      : null;

  // Format calculatedValue as 0.00m
  const formattedCalculatedValue = calculatedValue === ""
    ? ""
    : `${Math.round(calculatedValue * 100) / 100}m`;

 const formattedH1Angle = Data ? (Data.h1_angle * 0.1).toFixed(1) + "%" : "";

  return (
    <>
      <CardContent>
        <Grid
          container
          direction="row"
          spacing={10}
          justifyContent="space-evenly"
        >
          <Grid item xs={3}>
            <Grid container direction="row" item xs={12}>
              <CustomCardHeader
                title={
                  <Typography variant="body1" className="databox-font">
                    ゲート状態
                  </Typography>
                }
              />
            </Grid>
          </Grid>

          <Grid container direction="row" item xs={7}>
            <Grid container direction="row" item xs={12}>
              {["1", "0"].map((gate_status) => (
                <Grid container item xs={4} key={gate_status}>
                  {getStatusCard(
                    gate_condition === gate_status
                      ? gate_status === "1"
                        ? "#87A4D9"
                        : "#87A4D9"
                      : "white",
                    gate_status === "1" ? "全開" : "全閉"
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <div className="divider"></div>
      <CardContent className="taisa-card">
        <Grid container>
          <Grid item xs={12}>
            <Card inline className="data_card">
              <CardContent className="card_body">
                <Grid container className="water_card">
                  <Grid item xs={4} className="water_card_title">
                    <Typography variant="h5"> 開度</Typography>
                  </Grid>
                  <Grid item xs={8} align="center" className="water_data">
                    <Typography variant="h4">
                      {formattedCalculatedValue}({formattedH1Angle})
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}

export default Default;
