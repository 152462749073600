import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Databox from "./Databox";
import "./AppRight.css";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { stationList, selectedStationAtom } from "../store";
import { GLOBAL } from "../constants";
import axios from "../utilities/axios.utils";

function AppRight(props) {
  let history = useHistory();
  const ref = useRef(true);
  const noChangeFlg = useRef(false);
  const [allStations] = useAtom(stationList);
  const [, setNowSenStatus] = useState([]);

  const handleErrors = (res) => {
    if (res.ok) {
      return res;
    }
    switch (res.status) {
      case 400:
        throw Error("INVALID_TOKEN");
      case 401:
        throw Error("UNAUTHORIZED");
      case 500:
        throw Error("INTERNAL_SERVER_ERROR");
      case 502:
        throw Error("BAD_GATEWAY");
      case 404:
        throw Error("NOT_FOUND");
      default:
        throw Error("UNHANDLED_ERROR");
    }
  };

  const token = window.sessionStorage.getItem(GLOBAL.TOKEN_COOKIE_NAME);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      Timezone: "UTC",
    },
  };
  
  //const fetchWithErrorHandling = (url, options) =>
  //  fetch(url, options)
  //    .catch((e) => {
  //      throw Error(e);
  //    })
  //    .then(handleErrors)
  //    .then((res) => res.json());

  const fetchWithErrorHandling = (url, options) => axios.get(url, options);

  const unauthorizedRequest = async (id) => {
    try {
      let ret = await fetchWithErrorHandling(
        `${process.env.REACT_APP_BACKEND_URL}/api/stations/${id}/latest`,
        requestOptions
      );
      setNowSenStatus(ret.result);
    } catch (e) {
      setNowSenStatus([]);
    }
  };

  function getSenTblApi(id) {
    unauthorizedRequest(id);
  }

  useEffect(() => {
    if (modelName) {
      handleChgsensormodel(1);
    }
  }, []);

  const locationList = useMemo(() => {
    const res1 = allStations.filter(
      (element, index, self) =>
        self.findIndex((e) => e.sensor_model === element.sensor_model) === index
    );
    let eee = res1.map((item) => item["sensor_model"]);
    return eee;
  }, [allStations]);

  useEffect(() => {
    if (!modelName && locationList.length !== 0) {
      setModel(locationList[0]);
      clickSearchActre(locationList[0]);
    }
  }, [locationList]);

  const sList = (selectedModel) => {
    let ret6 = selectedModel.map((item) => item["sensor_model"]);
    let judge = "";
    judge = ret6[0];
    let ret7 = selectedModel.filter((station) => {
      return station.sensor_model === judge;
    });
    setlocListTblNmaeAll(ret7);
    let ret8 = ret7.map((item) => item["deployed_location"]);
    setlocListTblNmae(ret8);
    let ret9 = ret7.map((item) => item["device_id"]);
    var list1 = [];
    for (var i in ret9) {
      list1.push(ret8[i] + "," + "  DeviceID: " + ret9[i]);
    }
    setlocListTblNmae(list1);
  };

  const [modelName, setModel] = useState(!props.props ? "" : props.props.ken);
  const handleChgsensormodel = (e) => {
    setModel(e.target.value);

    var nowStation = allStations.filter((station) => {
      return station.sensor_model === e.target.value;
    });
    sList(nowStation);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current = false;
    } else {
    }
  }, [modelName]);

  const clickSearchActre = (sensor_model) => {
    console.log("click search!!");
    let res4 = allStations.filter((station) => {
      return station.sensor_model === sensor_model;
    });
    let ret5 = res4.filter(
      (element, index, self) =>
        self.findIndex((e) => e.sensor_model === element.sensor_model) === index
    );
    sList(ret5);
  };

  const [locListTbl, setlocListTblNmae] = useState([]);
  const [locListTblNmaeAll, setlocListTblNmaeAll] = useState([]);
  const [locName, setLoc] = useState(locListTbl[0]);
  const handleChgLoc = (e) => {
    let clickIndex = e.target.selectedIndex;
    if (clickIndex >= 0) {
      if (locListTblNmaeAll.length > clickIndex) {
        setLoc(e.target.value);
        setSearchLastTbl(locListTblNmaeAll[clickIndex]);
        getSenTblApi(locListTblNmaeAll[clickIndex].device_id);
        setSelectedStation(locListTblNmaeAll[clickIndex].device_id);
      }
    }
  };

  const [, setSearchLastTbl] = useState([]);
  const [selectedStation, setSelectedStation] = useAtom(selectedStationAtom);

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
effect is triggered whenever the `selectedStation` variable changes. */
  useEffect(() => {
    if (noChangeFlg.current === false && selectedStation !== null) {
      var nowStation = allStations.filter((station) => {
        return station.device_id === selectedStation;
      });
      if (nowStation.length) {
        setModel(nowStation[0].sensor_model);
      }
      let res4 = allStations.filter((station) => {
        return station.sensor_model === nowStation[0].sensor_model;
      });
      let ret5 = res4.filter(
        (element, index, self) =>
          self.findIndex((e) => e.sensor_model === element.sensor_model) ===
          index
      );
      let ret6 = ret5.map((item) => item["sensor_model"]);
      let judge = "";
      judge = ret6[0];
      let ret7 = res4.filter((station) => {
        return station.sensor_model === judge;
      });
      setlocListTblNmaeAll(ret7);

      let ret8 = ret7.map((item) => item["deployed_location"]);

      setlocListTblNmae(ret8);

      let ret9 = ret7.map((item) => item["device_id"]);
      var list1 = [];
      for (var i in ret9) {
        list1.push(ret8[i] + "," + "  DeviceID: " + ret9[i]);
      }
      setlocListTblNmae(list1);
    }
    noChangeFlg.current = false;

    // eslint-disable-next-line
  }, [selectedStation]);

  return (
    <>
      <div className="contents">
        {/* <div className="kobetusub-left"> */}
        <select
          className="kobetusub"
          name="prefecture"
          value={modelName}
          onChange={handleChgsensormodel}
        >
          {locationList.map((arrayItem) => (
            <option value={arrayItem} key={arrayItem}>
             {arrayItem}
            </option>
          ))}
        </select>
        {/* </div> */}
        {/* <div className="kobetusub-right">
          <Button
            className="kobetusub"
            variant="contained"
            color="primary"
            onClick={() => clickSearchAct(1)}
          >
            検索
          </Button>
        </div> */}
      </div>

      <div className="contents2">
        <select
          className="kobetuloc"
          name="location"
          multiple
          size="5"
          value={locName}
          onChange={handleChgLoc}
        >
          {locListTbl.map((arrayItem, idx) => (
            <option value={arrayItem} key={idx}>
              {" "}
              {idx + 1}.{arrayItem}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

export default AppRight;
